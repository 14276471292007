.window {
    position: absolute;
    border: 2px solid #000000;
    overflow: hidden;
    resize: both;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.window.active {
    border: none;
    background:
        linear-gradient(90deg, #333 50%, transparent 0) repeat-x,
        linear-gradient(90deg, #333 50%, transparent 0) repeat-x,
        linear-gradient(0deg, #333 50%, transparent 0) repeat-y,
        linear-gradient(0deg, #333 50%, transparent 0) repeat-y;
    background-size: 20px 2px, 20px 2px, 2px 20px, 2px 20px;
    background-position: 0 0, 0 100%, 0 0, 100% 0;
    animation: linearGradientMove .3s infinite linear;
}

.window-header {
    cursor: grab;
    margin: 2px;
    height: 100%;
    width: 100%;
}

.window-header:active {
    cursor: grabbing;
}

@keyframes linearGradientMove {
    100% {
        background-position: 20px 0, -20px 100%, 0 -20px, 100% 20px;
    }
}