button>svg {
  pointer-events: none;
}

.anticon-holder {
  width: 24px;
  justify-content: center;
}

#line-H {
  position: absolute;
  z-index: 9;
  left: 0;
  right: 0;
  display: flex;
  visibility: hidden;
  justify-content: center;
  cursor: row-resize;
  background-color: #DCDCDC;
}

.anticon {
  transition: filter 0.3s ease;
  /* Smooth transition for the glow effect */
}

/* Glow effect when active */
.anticon.glow {
  filter: drop-shadow(0 0 5px rgba(24, 144, 255, 1)) drop-shadow(0 0 10px rgba(24, 144, 255, 0.8));
}