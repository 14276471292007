#layer {
    /*pointer-events: none;*/
}

.nobull {
    list-style: none;
    padding: 0;
    margin: 0;
}

.svg-toolbar {
    position: absolute;
    z-index: 100;
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon {
    margin-left: 0px;
}

#slider-container {
    position: relative;
}

#vertical-line {
    position: absolute;
    width: 2px; /* Width of the line */
    background-color: black /* rgb(123, 201, 255); */
}

.ant-slider-track {
    background-color: transparent !important;
}

.ant-slider-handle {
    border: solid 2px black !important;
}