.bootstrap-demo .dropdown-trigger {
  border-radius: 0.25rem;
}

.bootstrap-demo .dropdown-trigger>span:after {
  font-size: 12px;
  color: #555;
}

.bootstrap-demo .toggle {
  font: normal normal normal 12px/1 FontAwesome;
  color: #555;
}

.bootstrap-demo .toggle.collapsed::after {
  content: "\f067";
}

.bootstrap-demo .toggle.expanded::after {
  content: "\f068";
}

.listOpt {
  padding: 3px 0px;

}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #6c757d;
  background-color: #eeeded;
}

.geoObjectTitle {
  font-size: 2.5vh;
  text-align: center;
  color: #000000;
}

#line-V {
	position: fixed;
	z-index: 9;
	top: 0;
	bottom: 0;
	height: 100vh;
  display: flex;
  visibility: hidden;
  cursor: col-resize;
  align-items: center;
  background-color: 	#DCDCDC;
}