.ant-tree-node-content-wrapper {
  min-width: 0px;
}

.site-tree-search-value {
  color: #f50;
}

.search-result-tree {
  display: inline;
  margin: 0;
}

.bold-text {
  font-weight: bold;
}

.ant-tree-draggable-icon {
  opacity: 1 !important;
}

.ant-dropdown-menu-sub {
  max-height: 300px;
  overflow-y: auto;
}