/*.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}
*/
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

.home,
.occurrence,
.description {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.breadcrumb {
  box-shadow: 1px 1px #d5d4d4;
  background: #eeeded;
  border: 1px solid #eeeded;
  border-radius: 5px;
  padding: 0 5px 0 10px !important;
  font-size: 16px;
  line-height:2em;
  }

  .container-fluid {
    max-width: 98% !important;/*Set your own width %; */
 }