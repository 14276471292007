.float-label {
  position: relative;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 11px;
  transition: 0.2s ease all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.as-placeholder {
  color: gray;
  top: 5px;
  max-width: calc(100% - 35px);
}

.as-label {
  top: -10px;
  font-size: 12px !important;
  background: white;
  padding: 0 4px;
  margin-left: -4px;
  max-width: calc(100% - 15px);
}