.navbar {
  background-color: #1b5e20;
  display: flex;
  justify-content: space-between; /* Change this line to create space between menu bars */
  align-items: center;
  z-index: 99;
  width: 100%;
}

.menu-bars {
  margin-left: 2rem; /* Use margin-left to move menu-bars to the left */
  font-size: 2rem;
  background: none;
}

.menu-bars-prof {
  margin-right: 2rem; /* Use margin-right to move menu-bars-prof to the right */
  font-size: 2rem;
  background: none;
}

.nav-menu-profilebar {
  background-color: #1b5e20;
  width: 200px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
  z-index: 10000;
}

.nav-menu-profilebar.active {
  right: 0;
  transition: 350ms;
}

.nav-menu-sidebar {
  background-color: #1b5e20;
  width: 200px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 10000;
}

.nav-menu-sidebar.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 0px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 4px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #2e7d32;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  margin-left: -2rem;
  background-color: #1b5e20;
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
  
.nav-menu-sidebar.active span, .nav-menu-profilebar.active span {
  margin-left: 16px;
}
 

/*
#sidebar {
    min-width: 200px;
    max-width: 200px;
    color: #fff;
    transition: all 0.3s;
    height: 100vh;
}

#sidebar.active {
    margin-left: -200px;
}

#sidebar .sidebar-header {
    padding: 20px;
    margin-top: 10px;
}

#sidebar ul.components {
    padding: 20px 0;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    color: #000;
    background: #fff;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #000;
    background: #fff;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

*/