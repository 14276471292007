.title {
    margin-top: 6%;
}

.links {
    min-height: 35vh;
    margin-top: 10%;
}

.home-footer {
    margin-top: 10%;
}